
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerGuideLogList, exportBloggerGuideLogList } from "@/api/request/blogger";

//组件
@Component({
  name: "BloggerGuideRecord",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = false; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "",
    blogger_id_str: "",

    //临时数据
    level: 0,
    user_id: 0,
    country: "",
    nick_name: "",
    blogger_id: 0,
    call_status: -1,
    blogger_nick_name: "",
  };

  //等级列表
  private levelList: any[] = [
    { id: 0, name: "全部博主" },
    { id: 1, name: "见习博主" },
    { id: 2, name: "新晋博主" },
    { id: 3, name: "优秀博主" },
    { id: 4, name: "金牌博主" },
    { id: 5, name: "铂金博主" },
    { id: 6, name: "钻石博主" },
    { id: 7, name: "皇冠博主" },
    { id: 8, name: "首席博主" },
  ];

  //在线状态
  private callStatusList: any[] = [
    { id: -1, name: "全部发起视频状态" },
    { id: 0, name: "没有视频" },
    { id: 1, name: "有视频" },
  ];

  //创建调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerGuideLogList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await exportBloggerGuideLogList(this.listQuery);

    //保存数据
    saveAs(data, "博主引导记录列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }
}
